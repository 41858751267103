<template>
  <div>
    <div class="nav">订购服务/购买标签</div>
    <div class="newProject">
      <div class="left">
        <div class="title" style="margin-top:50px">巡查蝠标签</div>
        <el-row>
          <el-col :span="3" class="nav_title">
            标签版本
          </el-col>
          <el-col :span="10" class="nav_content">
            <el-radio-group v-model="radio">
              <el-radio :label="item.id" style="font-size:18px" v-for="item in this.productHardArr" :key="item.id"><span style="font-size:18px">{{item.name}}</span></el-radio>
            </el-radio-group>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3" class="nav_title">
            单价
          </el-col>
          <el-col :span="16" class="nav_content" style="font-size:24px;font-weight:600;color:red">
            ￥{{this.markPrice}} / 个
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3" class="nav_title">
            数量
          </el-col>
          <el-col :span="16" class="nav_content" style="color:red;font-size:18px">
            <el-input-number v-model="quantity" size="small" :min="1" label="个"></el-input-number>
          </el-col>
        </el-row>
        <el-row style="margin-top:20px">
          <el-col :span="3" class="nav_title">
            收件人姓名
          </el-col>
          <el-col :span="6" class="nav_content">
            <el-input placeholder="请输入收货人姓名" v-model="order.recipient_name">
            </el-input>
          </el-col>
        </el-row>
        <el-row style="margin-top:20px">
          <el-col :span="3" class="nav_title">
            手机号
          </el-col>
          <el-col :span="6" class="nav_content">
            <el-input placeholder="请输入收货人手机号" v-model="order.phone">
            </el-input>
          </el-col>
        </el-row>
        <el-row style="margin-top:20px">
          <el-col :span="3" class="nav_title">
            所在地区
          </el-col>
          <el-col :span="6" class="nav_content">
            <el-cascader v-model="province" :options="options" @change="handleChange" style="width:283px"></el-cascader>
          </el-col>
        </el-row>
        <el-row style="margin-top:20px">
          <el-col :span="3" class="nav_title">
            收件地址
          </el-col>
          <el-col :span="6" class="nav_content">
            <el-input type="textarea" rows='2' placeholder="请输入详细的收件地址" v-model="order.address" show-word-limit>
            </el-input>
          </el-col>
        </el-row>
        <el-row style="margin-top:20px">
          <el-col :span="3" class="nav_title">
            备注
          </el-col>
          <el-col :span="6" class="nav_content">
            <el-input type="textarea" rows='3' placeholder="请输入备注" v-model="order.remark" maxlength="30" show-word-limit>
            </el-input>
          </el-col>
        </el-row>
        <el-row style="margin-top:20px">
          <el-col :span="3" class="nav_title">
            快递费用
          </el-col>
          <el-col :span="6" class="nav_content" style="font-size:24px;font-weight:600;color:red">
            ￥{{this.postPrice}}
          </el-col>
        </el-row>
        <el-row style="margin-top:20px">
          <el-col :span="2" :offset="19" class="nav_title">
            订单金额
          </el-col>
          <el-col :span="3" class="nav_content" style="color:red;font-weight:500;font-size:24px;margin-left:-10px">
            ￥{{total_price}}
          </el-col>
        </el-row>
        <el-row style="margin-top:0px">
          <el-col :offset="18" :span="10" class="nav_title">

            <el-checkbox size="medium" v-model="agree"></el-checkbox> <span style="font-size:14px;line-height:20px;font-weight:400;margin-right:10px">同意</span>
            <el-link style="font-size:14px;line-height:20px;margin-top:-4px;margin-right:10px" type="primary" href="http://obs.i-patrol.cn/patrol_paid_service_agreement.html" target="_blank">
              《 巡查蝠付费版服务协议 》
            </el-link>
          </el-col>
        </el-row>
        <el-row>
          <el-col :offset="22">
            <el-button :type="agree?'primary':'info'" style="margin-left:-20px" :disabled="!agree" round size="mini" @click="post">提交</el-button>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { order } from '@/utils/api';
import { isTelAvailable } from '@/utils/verification';
import location from '@/utils/province_city_district.json';
import qs from 'qs';
export default {
  name:"payHardWare",
  data() {
    return {
      radio: 'renew',
      priceArr: [],
      agree: false,
      order: {
        remark: '',
        phone: '',
        recipient_name: '',
        address: '',
        city_number: '',
        province_number: '',
        district_number: ''
      },
      quantity: 1,
      product: '',
      productHardArr: [],
      markPrice: '',
      options: [],
      province: '',
      postPrice: 20
    };
  },
  computed: {
    user_info() {
      return this.$store.state.user_info;
    },
    total_price() {
      return (this.quantity * this.markPrice + this.postPrice).toFixed(2);
    }
  },
  methods: {
    async getPriceList(cate) {
      const res = await this.$http.get(
        order.listProduct + '?scene=' + 'hardware'
      );
      this.productHardArr = res.data;
      this.radio = res.data[0].id;
      this.markPrice = res.data[0].price;
    },
    async post() {
      let par = {
        product_id: this.radio,
        quantity: this.quantity,
        recipient: this.order
      };
      if (!this.order.phone) {
        return this.$message.error('请填写收件人联系电话');
      }
      if (!this.order.remark) {
        return this.$message.error('请填写联系备注');
      }
      if (!this.order.district_number) {
        return this.$message.error('请选择省、市、区');
      }
      if (!this.order.address) {
        return this.$message.error('请填写详细地址');
      }
      if (!isTelAvailable(this.order.phone)) {
        return this.$message.error('请输入正确的电话号码');
      }
      const res = await this.$http.post(order.hardware, par);
      if (res.code == 0) {
        this.order.id = res.data.id;
        this.order.numbers = res.data.number.number;
        this.order.name = this.$store.state.current_project.organization.name;
        this.order.projectName = this.productHardArr.find(
          item => item.id == this.radio
        ).name;
        this.order.recipient = {
          address: this.order.address,
          phone: this.order.phone,
          recipient_name: this.order.recipient_name
        };
        this.order.order_items = [
          {
            price: this.productHardArr.find(item => item.id == this.radio).price,
            quantity:this.quantity
          },{
            price:this.postPrice
          }
        ];
        this.order.price=this.total_price
        // return console.log(this.productHardArr);
        this.$router.push({
          name: 'pay',
          params: {
            info: this.order
          }
        });
      } else {
        this.$message.error('将三秒后跳转前往订单列表页面');
        setTimeout(() => {
          this.$router.push('orderlist');
        }, 3000);
      }
    },
    editData() {
      if (location.length > 0) {
        location.forEach(item1 => {
          item1.label = item1.name;
          item1.value = item1.number;
          item1.children = item1.city;
          item1.children.forEach(item2 => {
            item2.label = item2.name;
            item2.value = item2.number;
            item2.children = item2.district;
            item2.children.forEach(item3 => {
              item3.label = item3.name;
              item3.value = item3.number;
            });
          });
        });
        this.options = location;
      }
    },
    handleChange(value) {
      this.order.province_number = value[0];
      this.order.city_number = value[1];
      this.order.district_number = value[2];
    },
    async getPostPrice() {
      const res = await this.$http.get(
        order.listProduct + '?scene=' + 'logistics'
      );
      this.postPrice = res.data[0].original_price - 0;
    }
  },
  created() {
    this.getPriceList();
    this.editData();
    this.getPostPrice();
  },
  mounted() {},
  updated() {
    this.editData();
  },
  watch: {
    radio: {
      handler(value) {
        console.log(this.productHardArr);
        this.markPrice = this.productHardArr.find(
          item => item.id == value
        ).price;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.newProject {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 1200px;
  height: 900px;
  background: #ffffff;
  box-shadow: 0px 2px 15px 0px rgba(0, 26, 59, 0.26);
  border: 1px solid #e6ecf5;
  margin: 20px auto;
  .left {
    flex: 1;
    padding-left: 30px;
    padding-right: 30px;
    .title {
      font-size: $fontSizeMap-20;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
      line-height: 28px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}
.nav {
  background-color: #fff;
  width: 1200px;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 5px;
  box-shadow: 0px 2px 15px 0px rgba(0, 26, 59, 0.26);
  color: #666666;
  padding-left: 30px;
}
.el-table--mini /deep/ td,
.el-table--mini /deep/th {
  padding: 0;
}
.el-table /deep/.cell {
  // padding: 0;
  // height: 20px;
  box-sizing: border-box;
  overflow: hidden;
}
.el-table /deep/ td {
  width: 100%;
}
.nav_title {
  font-weight: 700;
  font-size: 16px;
  line-height: 50px;
}
.nav_content {
  font-size: 16px;
  line-height: 50px;
}
</style>
